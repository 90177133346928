import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Rightcontainer from '../Components/Rightcontainer'
import Header from '../Components/Header'
import '../css/terms.css'
import axios from 'axios'
import { Interweave } from 'interweave'
const RefundPolicy = () => {
  const [data, setData] = useState()

   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
 // +++++++++++++++++++++++++++++ 


  const getdata = () => {
 
    axios
      .get(baseUrl+`api/term/condition/Refund_Policy`)
      .then(res => {
        setData(res.data[0].Desc)
    
      })
  }
  useEffect(() => {
    getdata()
  }, [])

  return (
    <div>
      <div
        className='leftContainer mb_space'
      >
        <div className='mt-5 py-4 px-3'>
          <div className='m-3'>
            <h1 className='profile_headings'>Refund Policy</h1>
           

            <>
              <p className='p2 small_para'>
                Thanks for being a patron with NK  WEBTECH PRIVATE LIMITED
                (referred as “NKADDA”)
                <span className='Apple-converted-space'>&nbsp; </span>. If you
                are not entirely satisfied with your subscription, we are here
                to help.
              </p>
              <p className='other_page_small_headings'>Refund</p>
              <p className='p2 small_para'>
                Once we receive your Refund request, we will inspect it and
                notify you on the status of your refund.
              </p>
              <p className='p2 small_para'>
                If your refund request is approved, we will initiate a refund to
                your credit card (or original method of payment) within 7
                working days. You will receive the credit within a certain
                amount of days, depending on your card issuer's policies.
              </p>
              <p className='p2 small_para'>
                In case of unforeseen technical glitch, NK  WEBTECH PRIVATE
                LIMITED would refund subscription upon reviewing the complaint.
                Final decision lies with the company.
              </p>
            </>
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </div>
  )
}
export default RefundPolicy
