import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import Rightcontainer from '../Components/Rightcontainer'
import '../css/terms.css'
import { Interweave } from 'interweave'
import axios from 'axios'
const TDSPolicy = () => {

  // BASE URL
 
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
}
// +++++++++++++++++++++++++++++ 
  const [data, setData] = useState()
  const getdata = () => {
   
    axios
      .get(baseUrl+`api/term/condition/term-condition`)
      .then(res => {
        setData(res.data[0].Desc)
        // console.log(res.data[0].Type);
      })
  }
  useEffect(() => {
    getdata()
  }, [])

  const [TotalWithdrawal, setTotalWithdrawal] = useState(0)
  const [Totaldeposite, setTotaldeposite] = useState(0)
  const [TotalWithdrawn, setTotalWithdrawn] = useState(0)

  const [NetWinningBalance, setNetWinningBalance] = useState(0)
  const [TDS, setTDS] = useState(0)

  const CalculateTDS = e => {
    e.preventDefault()
    const num1 = parseFloat(TotalWithdrawal)
    const num2 = parseFloat(Totaldeposite)
    const num3 = parseFloat(TotalWithdrawn)

    const totalWinning = num1 + num3

    const winningBalance = totalWinning - num2

    const thirtyPercent = 0.3 // 30% as a decimal
    setTDS(winningBalance * thirtyPercent)
    setNetWinningBalance(winningBalance - TDS)

    console.log('totalWinning', setNetWinningBalance, TDS)
  }

  return (
    <div>
      <div className='leftContainer mb_space'>
        <Header />
        <div className='mt-5 py-4 px-3'>
          <div className='m-3'>
            <h1 className='profile_headings'>TDS-Policy</h1>

            <p className='small_para'>
              In line with the provisions contained in Finance Act, 2023,
              following TDS policy is applicable for all players on KhelBro from
              1 Apr, 2023.
            </p>
            <ul>
              <li className=' small_para'>
                TDS is applicable at the time of withdrawal or deposit refund
              </li>
              <li className=' small_para'>
                30% TDS is applicable on any positive net winnings at the time
                of withdrawal
              </li>
              <li className=' small_para'>
                TDS is also applicable at the end of financial year on year end
                wallet balance by considering it as a withdrawal amount. Post
                applying TDS on the remaining balance (as per policy), the
                remaining amount will be carried forward to the next financial
                year as deposit balance. Carried forward balance will be
                considered as investment for next year and TDS would not be
                applicable for that amount.
              </li>
            </ul>
            <p className='small_para'>
              Here, Net winnings = Total Withdrawals - Total Deposits (in a
              financial year). Apr 1 to Mar 31 duration is considered a
              financial year.
            </p>
            <h3 className='other_page_small_headings'>
              How was TDS deducted before Apr 1, 2023 :
            </h3>
            <ul>
              <li className='small_para'>
                Before Apr 1 2023, TDS was deducted at a game level when the
                winnings were greater than 10K.
              </li>
            </ul>
            <p className=' small_para'>
              Example : Previously if I won Rs 11000 in a game, then 30% TDS
              will be deducted i.e. 3300 and hence Rs 7700 will be credited in
              the withdrawal wallet.
            </p>
            <h3 className='other_page_small_headings'>
              How is the current TDS policy different from the last TDS policy
              of FY 2022- 2023
            </h3>
            <ul>
              <li className='small_para'>
                Post Apr 1, if I win some amount let's assume Rs 11000, NO TDS
                will be deducted while crediting that amount in the withdrawal
                wallet. Entire Rs 11000 will be transferred to the winnings
                wallet.
              </li>
              <li className='small_para'>
                Only when the user will try to withdraw any amount from the
                wallet, we will look at the net winnings and basis that
                calculate the TDS applicable.{' '}
              </li>
              <li className='small_para'>
                Hence we will deduct TDS only when the withdrawal running total
                is more than the Deposits. If the user is in loss, there will be
                no TDS. Hence this will be beneficial to the users.{' '}
              </li>
            </ul>
            <p className='small_para'>
              Please note that the TDS Policy is based on the understanding of
              the provisions as introduced/ amended by Finance Act, 2023 and the
              Company reserves its right to modify/ change/ amend the TDS Policy
              basis law applicable at the relevant time. Attached is the finance
              bill amendment which says that the policy will be applicable from
              Apr 1, 2023 rather than July 1, 2023.
            </p>

            <h3 className='other_page_small_headings'>
              What Does It Mean For Players?
            </h3>
            <p className='small_para'>
              Find below a few scenarios to understand the application of the
              aforementioned TDS Policy.
            </p>
            <p className='small_para'>
              <b> Scenario 1: </b>If your net winnings (including the withdrawal
              amount) is more than 0, then 30% TDS will be deducted on the net
              winnings at the time of withdrawal
            </p>
            <h3 className='other_page_small_headings'>Example:</h3>
            <div className=''>
              <table className='table table-bordered mb-4'>
                <tr>
                  <td>Total withdrawals in the financial year(A)</td>{' '}
                  <td>₹5,000</td>
                </tr>
                <tr>
                  <td>Total deposits in the financial year(B)</td>{' '}
                  <td>₹10,000</td>
                </tr>
              </table>
            </div>

            <p className='small_para'>
              In the above scenario, if you want to withdraw ₹7,000 , then the
              net winnings will be:
            </p>
            <div className=''>
              <table className='table table-bordered mb-4'>
                <tr>
                  <td>Amount being withdrawn by the player(C)</td>{' '}
                  <td>₹7,000</td>
                </tr>
                <tr>
                  <td>Net winnings(A+C - B)</td> <td>₹2,000</td>
                </tr>
                <tr>
                  <td>30% TDS Applicable on Net Winnings(D)</td> <td>₹600</td>
                </tr>
                <tr>
                  <td>Amount to be credited in the bank account(C-D)</td>{' '}
                  <td>₹6,400</td>
                </tr>
              </table>
            </div>

            <p className='small_para'>
              <b> Zero TDS Withdrawal amount:</b> If you withdraw upto ₹5,000 ,
              then there will be zero TDS deducted as net winnings will be ₹0
              (₹5,000 + ₹5,000 - ₹10,000). See calculation below:
            </p>
            <table className='table table-bordered mb-4'>
              <tr>
                <td>Total withdrawals in the financial year(A)</td>{' '}
                <td>₹5,000</td>
              </tr>
              <tr>
                <td>Total deposits in the financial year(B)</td>{' '}
                <td>₹10,000</td>
              </tr>
              <tr>
                <td>Amount being withdrawn by the player(C)</td> <td>₹5,000</td>
              </tr>
              <tr>
                <td>Net winnings(A+C - B)</td> <td>₹0</td>
              </tr>
            </table>

            <p className='small_para'>
              <b> Scenario 2:</b> If you have paid TDS in previous withdrawals,
              then no TDS is applicable on withdrawals till your net winnings
              crosses the amount for which you have already paid TDS.
            </p>
            <table className='table table-bordered mb-4'>
              <tr>
                <td>Total withdrawals in the financial year</td>{' '}
                <td>₹20,000</td>
              </tr>
              <tr>
                <td>Total deposits in the financial year</td> <td>₹20,000</td>
              </tr>
              <tr>
                <td>
                  TDS paid in the financial year <br />
                  (TDS paid on winnings of ₹6,000 as initially you had positive
                  net winnings of ₹6,000)
                </td>{' '}
                <td>₹1,800</td>
              </tr>
            </table>
            <p className='small_para'>
              In this scenario, No TDS applicable till your net winnings crosses
              ₹6,000 (winnings already taxed):
            </p>

            <ul>
              <li className='small_para'>
                <b> Zero TDS Withdrawal amount:</b> If you withdraw up to
                ₹6,000, no TDS is applicable
              </li>
              <li className='small_para'>
                If you withdraw ₹10, 000, then 30% TDS is applicable only on
                ₹4,000 winnings i.e. ₹1,200.
              </li>
            </ul>

            <p className='small_para'>
              <b> Scenario 3:</b> You have paid TDS on initial winnings during
              the financial year but your net winnings have reduced post that.
            </p>
            <table className='table table-bordered mb-4'>
              <tr>
                <td>Total withdrawals in the financial year(A)</td>{' '}
                <td>₹40,000</td>
              </tr>
              <tr>
                <td>Total deposits in the financial year(B)</td>{' '}
                <td>₹20,000</td>
              </tr>
              <tr>
                <td>Net winnings in the financial year(A-B)</td>{' '}
                <td>₹20,000</td>
              </tr>
              <tr>
                <td>TDS paid till date</td> <td>₹6,000</td>
              </tr>
            </table>
            <p className='small_para'>
              Post this, if your net winnings reduces to ₹10,000, then, subject
              to applicable law, the excess TDS deducted can be claimed as
              refund in your annual income tax filing.
            </p>

            <p className='small_para'>
              <b> Scenario 4:</b> At the end of the financial year, if you have
              an amount in your account, then TDS will be calculated on that
              amount by considering it as a withdrawal of the financial year and
              remaining amount will be carried forward to next year as starting
              deposit amount. Withdrawal from the starting deposit amount will
              not be subject to TDS in the next year.
            </p>

            <table className='table table-bordered mb-4'>
              <tr>
                <td>Total withdrawals in the financial year(A)</td>{' '}
                <td>₹40,000</td>
              </tr>
              <tr>
                <td>Total deposits in the financial year(B)</td>{' '}
                <td>₹20,000</td>
              </tr>
              <tr>
                <td>
                  TDS paid till date(C) <br />
                  (on winning amount of ₹5,000 as initially you had positive net
                  winnings of ₹5,000){' '}
                </td>{' '}
                <td>₹1,500</td>
              </tr>
              <tr>
                <td>Account balance at the end of Mar 31, 2024(D)</td>{' '}
                <td>₹30,000</td>
              </tr>
            </table>
            <p className='small_para'>
              In above scenario, following calculation is done:
            </p>

            <table className='table table-bordered mb-4'>
              <tr>
                <td>Total withdrawals in the financial year(A+D)</td>{' '}
                <td>₹70,000</td>
              </tr>
              <tr>
                <td>Total deposits in the financial year(B)</td>{' '}
                <td>₹40,000</td>
              </tr>
              <tr>
                <td>Net winnings(A+D-B)</td> <td>₹30,000</td>
              </tr>
              <tr>
                <td>30% TDS Applicable on Net Winnings(E)</td> <td>₹9,000</td>
              </tr>
              <tr>
                <td>TDS paid till date(C)</td> <td>₹15,00</td>
              </tr>
              <tr>
                <td>TDS Remaining to be paid(E-C)</td> <td>₹75,00</td>
              </tr>
            </table>
            <p className='small_para'>
              Thus, ₹7,500 TDS is deducted from your account balance at the end
              of Mar 31, 2024 and remaining balance of ₹22,500 will be carried
              forward to the next financial year as deposit amount. You will not
              have to pay TDS in the next financial year on withdrawal out of
              this amount.
            </p>
            <p className='small_para'>
              <b> Special Note:</b> In case you have an amount on the table at
              the end of the financial year, then it will not be considered in
              next year's starting balance.
            </p>

            <div>
              <h3 className='other_page_small_headings'>
                Sample TDS Calculator
              </h3>
              <form onSubmit={CalculateTDS}>
                <div>
                  <label className='commaon_label'>
                    Total Withdrawal in this Financial Year (A):
                  </label>
                  <input
                    type='number'
                    className='commaon_input_box'
                    value={TotalWithdrawal}
                    onChange={e => {
                      setTotalWithdrawal(e.target.value)
                    }}
                  />
                </div>
                <div>
                  <label className='commaon_label'>
                    Total Deposits in the Financial Year (B):
                  </label>
                  <input
                    type='number'
                    className='commaon_input_box'
                    value={Totaldeposite}
                    onChange={e => {
                      setTotaldeposite(e.target.value)
                    }}
                  />
                </div>
                <div>
                  <label className='commaon_label'>
                    Amount Being Withdrawn By the Player (C):
                  </label>
                  <input
                    type='number'
                    className='commaon_input_box'
                    value={TotalWithdrawn}
                    onChange={e => {
                      setTotalWithdrawn(e.target.value)
                    }}
                  />
                </div>

                <div className='logout_div'>
                  <button type='submit'>Calculate TDS</button>
                </div>
                <div className='border p-3'>
                  <div>
                    <label className='commaon_label'>
                      Net Winning (A+C)-B:
                    </label>
                    <p className='small_para'> {NetWinningBalance}</p>
                  </div>
                  <div>
                    <label className='commaon_label'>
                      TDS Applicable (30% on net winnings)
                    </label>
                    <p className='small_para'> {TDS}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </div>
  )
}
export default TDSPolicy
